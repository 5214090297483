import React from 'react';
import SectionHeader from '../components/SectionHeader';

export default function ExperiencePage() {
  return (
    <>
      <SectionHeader sectionTitle="My experience" />
      <p>Under construction</p>
    </>
  );
}
